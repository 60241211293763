<template>
  <div>
    <a-modal
      v-model="visibleModalManage"
      class="mod"
      :title="status.isEdit ? 'Form Edit Template Description' : 'Form Create Template Description'"
      @cancel="toggleModalManage"
      centered
    >
      <form-template-report-description
        :loadingValidate="formValidate.loading"
        :dataPayload="newData"
        @handle-change="handleChange"
        @validationForm="validationForm"
        :subjects="subjects"
        :levels="levels"
        :idMapel="idMapel"
        :idLevel="idLevel"
        :types="types"
        :type="selectType"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManage">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManage"
          @click="formValidate.loading = true"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-md-flex align-items center">
      <a-select
        @change="changeMapel()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="!isMobile ? 'mr-3' : 'mb-3'"
        style="min-width: 150px"
        v-model="idMapel"
        size="large"
      >
        <a-select-option
          v-for="subject in subjects"
          :value="subject.id"
          :key="subject.id"
          >{{ subject.nama }}</a-select-option
        ></a-select
      >
      <a-select
        style="min-width: 150px"
        @change="fetchData()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="isMobile ? 'mb-3' : 'mr-3'"
        v-model="idLevel"
        size="large"
      >
        <a-select-option
          v-for="level in levels"
          :value="level.id"
          :key="level.id"
          >{{ level.nama }}</a-select-option
        ></a-select
      >
      <a-select
        style="min-width: 150px"
        @change="fetchData()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="isMobile ? 'mb-3' : null"
        v-model="selectType"
        size="large"
      >
        <a-select-option
          v-for="type in types"
          :value="type.key"
          :key="type.key"
          >{{ type.name }}</a-select-option
        ></a-select
      >
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          @click.prevent="toggleModalManage('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Description</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        class="hasan-table-overflow"
        :columns="columns"
        :data-source="dataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <div slot="nullStr" slot-scope="text">{{ text || "-" }}</div>
        <div slot="action" class="d-flex flex-column" slot-scope="value, record">
          <a-button
            class="mb-3 text-warning border border-warning"
            size="large"
            @click.prevent="toggleModalManage('edit', record)"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            class="text-danger border border-danger"
            size="large"
            @click.prevent="handleDelete(record)"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
const FormTemplateReportDescription = () => import('@/views/Teacher/Planning/New/TemplateReportDescription/Form')

const columns = [
  {
    title: 'Predicate',
    dataIndex: 'namaPredikat',
    key: 'predikat',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    scopedSlots: { customRender: 'nullStr' },
    width: 500,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

export default {
  name: 'TemplateReportDescriptionSubject',
  components: {
    FormTemplateReportDescription,
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns,
      visibleModalManage: false,
      visibleModalAddSession: false,
      loadingActionModalManage: false,
      loadingDelete: false,
      loadingTable: false,
      dataTable: [],
      pagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newData: {
        id_predikat: null,
        description: '',
      },
      currentDataEdit: {},
      idMapel: null,
      idLevel: null,
      listSubjects: [],
      listLevels: [],
      formValidate: {
        loading: false,
        isValidated: false,
      },
      types: [
        {
          key: 'knowledge',
          name: 'Knowledge',
        },
        {
          key: 'skill',
          name: 'Skill',
        },
      ],
      selectType: 'knowledge',
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      // console.log(value, column, this.newData)
      if (column === 'variable') {
        this.newData.description += value
      } else {
        this.newData[column] = value
      }
    },
    toggleModalManage(status, data) {
      this.visibleModalManage = !this.visibleModalManage
      if (!this.visibleModalManage) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newData = {
            id_predikat: null,
            description: '',
          }
          this.currentDataEdit = {}
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.currentDataEdit = data
          const { id_predikat: idPredikat, type, description } = data
          this.newData = {
            id_predikat: idPredikat,
            description: description ?? '',
          }
          this.selectType = type
        }
      }
    },
    handleOkModalManage() {
      if (!this.formValidate.isValidated) {
        return this.$notification.error({
          message: 'Validation Failed',
          description: 'Please check your fields.',
        })
      }
      const content = `${this.status.isEdit ? 'Are you sure to edit this template?' : 'Are you sure to create new template'}`
      this.$confirm({
        title: 'Warning',
        content: (
          <div>{content}</div>
        ),
        onOk: async () => {
          try {
            this.loadingActionModalManage = true
            const payload = this.status.isEdit ? {
              id: this.currentDataEdit.id,
              dataTemplate: {
                ...this.newData,
                id_mata_pelajaran: this.idMapel,
                id_level: this.idLevel,
                type: this.selectType,
              },
            } : {
              idMapel: this.idMapel,
              idLevel: this.idLevel,
              dataTemplate: {
                ...this.newData,
                id_mata_pelajaran: this.idMapel,
                id_level: this.idLevel,
                type: this.selectType,
              },
            }
            await this.$store.dispatch(`admin/${this.status.isEdit ? 'EDIT' : 'POST'}_TEMPLATE`, payload)
            this.loadingActionModalManage = false
            this.toggleModalManage()
            await this.fetchData()
            const description = this.status.isEdit ? 'Template is updated' : 'Template is created'
            this.$notification.success({
              message: 'Success',
              description,
            })
          } catch (err) {
            const description = this.status.isEdit ? 'Template is not updated' : 'Template is not created'
            this.$notification.error({
              message: 'Failed',
              description,
            })
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
        cancelText: 'Cancel',
      })
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this template?</div>
        ),
        onOk: async () => {
          try {
            this.loadingDelete = true
            await this.$store.dispatch('admin/DELETE_TEMPLATE', {
              id: data.id,
            })
            await this.fetchData()
            this.loadingDelete = false
            this.$notification.success({
              message: 'Success',
              description:
                'Template is deleted',
            })
          } catch (err) {
            this.loadingDelete = false
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: 'Template has not been deleted',
            })
          }
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async fetchData() {
      try {
        this.loadingTable = true
        const data = await this.$store.dispatch('admin/FETCH_TEMPLATE', { idMapel: this.idMapel, idLevel: this.idLevel, type: this.selectType })
        this.loadingTable = false
        // const pagination = { ...this.pagination }
        // pagination.total = data.total
        // this.pagination = pagination
        // console.log(res)
        this.dataTable = data.map(el => {
          return {
            ...el,
            key: el.id,
            namaPredikat: el.predikat?.nama,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchSubjectsAndLevelsByTeacher() {
      try {
        const { data } = await this.$store.dispatch('subject/FETCH_TEACHER_SUBJECTS')

        this.listSubjects = data.subjects
        this.listLevels = data.levels
        this.idMapel = this.listSubjects[0]?.id
        this.idLevel = this.listLevels[0]?.id
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    validationForm(isValidated) {
      this.formValidate = {
        loading: false,
        isValidated,
      }
      this.handleOkModalManage()
    },
    async changeMapel() {
      try {
        this.$emit('changeMapel', this.idMapel)
        this.idLevel = this.levels[0]?.id
        await this.fetchData()
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async created() {
    try {
      this.idMapel = this.subjects[0]?.id
      await this.changeMapel()
    } catch (err) {
      console.log(err)
      this.$notification.error({
        message: 'ERROR_SERVER',
      })
    }
    // this.dataTable = [
    //   {
    //     key: 1,
    //     no: '3',
    //     nama_pengetahuan: 'Menjelaskan konsep campuran dan zat tunggal (unsur dan senyawa), sifat fisika dan kimia, perubahan fisika dan kimia dalam kehidupan sehari-hari',
    //     nama_keterampilan: 'Memeragakan konsep campuran dan zat tunggal (unsur dan senyawa), sifat fisika dan kimia, perubahan fisika dan kimia dalam kehidupan sehari-hari',
    //     session: [
    //       {
    //         key: 1,
    //         kelas: 'VII - 2',
    //         date: moment().format('DD MMM YYYY HH:mm'),
    //         isLive: true,
    //         assignmentType: 'Essay',
    //       },
    //     ],
    //   },
    // ]
  },
  computed: {
    // isValidateForm() {
    //   // if (this.newData.nama) {
    //   //   return false
    //   // }
    //   return true
    // },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style>
</style>
